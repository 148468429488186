import React from 'react';
import PropTypes from 'prop-types';
import { JSONLD, Generic, GenericCollection } from 'react-structured-data';
import blogPosting from './shared/blogPosting';

function renderBlogPosts(posts) {
  const jsonldPosts = posts.map((post, index) => {
    const mainEntityOfPage = index === 0;
    return blogPosting(post.node, mainEntityOfPage);
  });
  return <GenericCollection type="blogPosts">{jsonldPosts}</GenericCollection>;
}

export default function BlogJSONLD({ posts }) {
  return (
    <JSONLD dangerouslyExposeHtml>
      <Generic
        type="blog"
        jsonldtype="Blog"
        schema={{
          name: 'Lumosity blog',
          url: 'https://www.lumosity.com/en/blog',
          description: 'The official Lumosity Blog'
        }}
      >
        {renderBlogPosts(posts)}
      </Generic>
    </JSONLD>
  );
}

BlogJSONLD.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.object).isRequired
};
