import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Posts from '../../components/blog/posts';
import BlogJSONLD from '../../components/blog/SchemaDotOrg/blogJSONLD';
import i18n from '../../utils/i18n';

export default class BlogIndex extends React.Component {
  render() {
    const {
      location,
      data: { allContentfulBlogPost: { edges: posts } },
      pageContext: { locale }
    } = this.props;
    return (
      <>
        <Posts
          posts={posts}
          location={location}
          title="Lumosity - Blog"
          description="The official Lumosity blog."
          headline={i18n.t('blog.subNav.recentNews')}
          canonical={`${locale}/blog`}
          locale={locale}
        />
        <BlogJSONLD posts={posts.slice(0, 5)} />
      </>
    );
  }
}

BlogIndex.propTypes = {
  location: PropTypes.shape({
    href: PropTypes.string,
    pathname: PropTypes.string
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string
  }).isRequired
};

export const pageQuery = graphql`
  query BlogIndexQuery {
    allContentfulBlogPost(filter: {node_locale: {eq: "en"}}, sort: { fields: [publishDate], order: DESC }) {
      edges {
        ...BlogPost
      }
    }
  }
`
